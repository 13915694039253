<template>
  <div>
    <!--begin::User-->
    <div>
      <h3 class="card-label">
        {{ $t('MENU.sales_tax_settings') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>

      <div class="row">
        <template v-for="(row_1, index_1) in taxes_rules_for_tax_setting">
          <div class="col-md-6" v-for="(row_2, index_2) in  row_1.tax_rule_details"  :key="'l2'+index_2">
            <div class="card card-custom mt-5">
              <div class="card-body row">
                <div class="col-lg-12 mb-5">
                  <b-form-group  :label="row_2.name" v-slot="{ ariaDescribedby }">
                    <b-form-radio v-for="(row_3, index_3) in row_2.options" v-model="row_2.value" @input="setValues(row_2.id, $event)" :aria-describedby="ariaDescribedby" :name="'tax_'+row_2.id" :key="'tax_'+row_2.id+row_3.id" :value="row_3.value">{{ row_3.name }}</b-form-radio>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>
        </template>

      </div>
<!--      <div class="row">-->
<!--        <div class="col-md-6">-->
<!--          <div class="card card-custom">-->
<!--            <div class="card-body row">-->
<!--              <div class="col-lg-12 mb-5">-->
<!--                <b-form-group :label="$t('tax_settings.the_application_of_the_first_tax')" v-slot="{ ariaDescribedby }">-->
<!--                  <b-form-radio v-model="data.tax_on_principal_discount" :aria-describedby="ariaDescribedby" name="tax_on_principal_discount" value="1">{{ $t('tax_settings.apply_the_tax_on_the_principal_amount_before_deduction') }}</b-form-radio>-->
<!--                  <b-form-radio v-model="data.tax_on_principal_discount" :aria-describedby="ariaDescribedby" name="tax_on_principal_discount" value="2">{{ $t('tax_settings.apply_the_tax_on_the_principal_amount_after_deduction') }}</b-form-radio>-->
<!--                </b-form-group>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->




<!--          <div class="card card-custom mt-5">-->
<!--            <div class="card-body row">-->
<!--              <div class="col-lg-12 mb-5">-->
<!--                <b-form-group :label="$t('tax_settings.tax_free_product')" v-slot="{ ariaDescribedby }">-->
<!--                  <b-form-radio v-model="data.tax_on_profit_value" :aria-describedby="ariaDescribedby" name="tax_on_profit_value" value="1">{{ $t('tax_settings.application_of_tax_on_the_value_of_profit') }}</b-form-radio>-->
<!--                </b-form-group>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="col-md-6">-->
<!--          <div class="card card-custom">-->
<!--            <div class="card-body row">-->
<!--              <div class="col-lg-12 mb-5">-->
<!--                <b-form-group :label="$t('tax_settings.the_application_of_the_second_tax')" v-slot="{ ariaDescribedby }">-->
<!--                  <b-form-radio v-model="data.tax_on_principal_and_first_tax_discount" :aria-describedby="ariaDescribedby" name="tax_on_principal_and_first_tax_discount" value="1">{{ $t('tax_settings.apply_the_tax_on_the_principal_amount_the_first_tax_value_before_deduction') }}</b-form-radio>-->
<!--                  <b-form-radio v-model="data.tax_on_principal_and_first_tax_discount" :aria-describedby="ariaDescribedby" name="tax_on_principal_and_first_tax_discount" value="2">{{ $t('tax_settings.apply_the_tax_on_the_principal_amount_the_first_tax_value_after_deduction') }}</b-form-radio>-->
<!--                  <b-form-radio v-model="data.tax_on_principal_and_first_tax_discount" :aria-describedby="ariaDescribedby" name="tax_on_principal_and_first_tax_discount" value="3">{{ $t('tax_settings.apply_the_tax_on_the_principal_amount_before_the_discount_tax_2') }}</b-form-radio>-->
<!--                  <b-form-radio v-model="data.tax_on_principal_and_first_tax_discount" :aria-describedby="ariaDescribedby" name="tax_on_principal_and_first_tax_discount" value="4">{{ $t('tax_settings.apply_the_tax_on_the_principal_amount_after_the_discount_tax_2') }}</b-form-radio>-->
<!--                </b-form-group>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="reset" class="btn btn-primary mr-2" @click="save()">
              {{ $t('save') }}
            </button>
          </div>
        </div>
      </div>

    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Vue from "vue";

export default {
  name: "form-sales-taxes-settings",
  data() {
    return {
      mainRouteUpdate: 'settings/tax_rules_detail/update_value',
      mainRouteGet: 'settings/tax_rules_detail/get_value',
      mainRouteDependency: 'base/dependency',
      user_id: this.$route.params.user_id ? this.$route.params.user_id : null,
      // data: {
      // },
      values: [],
      validation: null,
      taxes_rules_for_tax_setting: [],

      type: 1,
    };
  },
  watch: {},
  methods: {
    save() {
      this.create();
    },

    create() {
      ApiService.patch(this.mainRouteUpdate, {
        values: this.values,
        type: this.type,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        Vue.prototype.$postStatus = true;
      }).catch((error) => {
        Vue.prototype.$postStatus = true;
        this.validation = error.response ? error.response.data.errors : null;
      });
    },


    async getData() {
      await ApiService.get(this.mainRouteGet+'/1').then((response) => {
        this.values = response.data.data ? response.data.data : [];

      });
    },

    async getTaxesRulesForTaxSetting() {
      await ApiService.get(this.mainRouteDependency + "/taxes_rules_for_tax_setting/1").then((response) => {
        this.taxes_rules_for_tax_setting = response.data.data;

      });
    },

    setValues(id, value){
      let _obj = this.values.find((row)=>row.id == id);
      if (_obj){
        this.values = this.values.map((row)=>{
          if (row.id == id){
            row.value = value;
          }
          return row;
        });
      }else {
        this.values.push({id: id, value: value});
      }

    }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: this.$t("MENU.sales_tax_settings")
    },
      {title: (this.idEditing ? this.$t('update') : this.$t('create'))}]);
    let promise = this.getTaxesRulesForTaxSetting();
    Promise.all([promise]).then(()=>{
      this.getData();
    })


  },
};
</script>


